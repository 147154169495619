import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import * as $ from 'jquery';
// import { MatterPopupComponent } from 'src/app/main/pages/matters/matter-popup/matter-popup.component';
// import { ContactDialogComponent } from './../../../main/pages/contact/contact-dialog/contact-dialog.component';
import { SortingDialogComponent } from 'src/app/main/sorting-dialog/sorting-dialog.component';
import { ResizeEvent } from 'angular-resizable-element';
import { Title } from '@angular/platform-browser';
import { DropDownTreeComponent, SelectEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
// import { MatterFolderDailogComponent } from 'src/app/layout/components/toolbar/matter-folder-dailog/matter-folder-dailog.component';
import { environment } from 'src/environments/environment';
import { MatSelect } from '@angular/material/select';
import { FilterSearchOptions } from 'src/app/_constant/dynamic-search-option.const';
import { MatterPopupComponent } from '@pages/matters/matter-popup/matter-popup.component';
import { ContactDialogComponent } from '@pages/contact/contact-dialog/contact-dialog.component';
import { HyperlinkNavigationService } from '@_services/hyperlink-navigation.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TableColumnsService } from '@_services/table-columns.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
 import { ToolbarSettingsModel, Permission, FileData} from '@syncfusion/ej2-filemanager';
import { ToolbarServiceService } from 'src/app/layout/components/toolbar/toolbar-service.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { FileManager } from '@syncfusion/ej2-filemanager';
import { FileManagerComponent } from '@syncfusion/ej2-angular-filemanager';
import { DatePipe } from '@angular/common';
import {
    createSpinner,
    showSpinner,
    hideSpinner,
  } from '@syncfusion/ej2-angular-popups';
@Component({
    selector: 'app-document-register',
    templateUrl: './document-register.component.html',
    styleUrls: ['./document-register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DocumentRegisterComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
    documentform: FormGroup;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    errorWarningData: any = {};
    isLoadingResults: boolean = false;
    highlightedRows: any;
    ColumnsObj = [];
    pageSize: any;
    tempColobj: any;
    displayedColumns: any;
    theme_type = localStorage.getItem('theme_type');
    logedInUser = JSON.parse(localStorage.getItem('currentUser'));
    currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = 'rgb(217, 217, 217)';
    DocNo = this.theme_type == "theme-default" ? 'Solicitor' : 'Client';
    DocumentAllData: any = [];
    dateColFilter = [];
    isDisplay: boolean = false;
    isClose: boolean = true;
    sortingDefaultState: any = {};
    FolderData: any = [];
    sortactive: any;
    sortDirection: any;
    lastSubFolder: any = "All";
    fields: Object = {};
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    activeMATTERGUID: any;
    mattersData: any;
    fileList: any = [];
    IsRegisterDocumentList: boolean = false;
    @ViewChild('folderList', { static: false }) public ddlelem: DropDownTreeComponent;
    FolderListArray: any = [];
    expandexNodelist: any = [];
    FolderDocumentList: any = [];
    public treeSettings: any = { loadOnDemand: true };
    isUplodedFileListShow: boolean = false;
    pushFolder: any = [];
    lastFolderName: string;
    lastNetDriveFolderFilter: any;
    LastFolderFullPath: string;
    showFolder: string;
    sendSaveFolder: string;
    FolderDataArray: any = [];
    windowNameId: any;
    selectedMatterTab: any;
    ApiRepoParm1: any;
    ApiRepoParm2: any;
    isGenrateReport: boolean = false;
    isChronoData: string;
    ChronologyLegalData: any;
    isOpenDesktopWord: boolean = false;
    isDocRegData: string;
    DocRegData: any = [];
    SafeCustodyData: any;
    isSafeCustody: string;
    DocumentRegisterSelectedFolder: any = [];
    FILTERS: FormArray;
    FILTERSLIST: FormArray;
    showFiltersList: boolean = true;
    optionalFilterList = [];
    saveAdvanceFilter = [];
    optionalFilters = [];
    optionalDateFilterPayload = [];
    activeFilters: any;
    detectedChangeRef = Math.random();
    totalApplicableFilterCount: number = 0;
    activeOptionalFilter: any;
    selectedFilterIs: any;
    _filterList = [];
    addFilter;
    AdvancedFiltersdata: any;
    Searchvalue: any;
    includeSubFolder: boolean = false
    HyperlinkColor: any;
    COLOR: any;
    resizableMousemove: () => void;
    resizableMouseup: () => void;
    class: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
  //  public ajaxSettings: object;
    public toolbarSettings:ToolbarSettingsModel;
    public view: string;
    public hostUrl: string = 'https://ej2-aspcore-service.azurewebsites.net/';
    public permission:Permission;
    public fileData:FileData[];
    public resultData =[];
    confirmDialogRef2: MatDialogRef<ConfirmPopupComponent>;
    oldIsTrackId: any;
    public dialogSettings: object;
    // @ViewChild('fileManager', { static: false }) fileManager!: FileManagerComponent;
    @ViewChild('fileManager')
    public fileManagerInstance: FileManagerComponent;
    currentFolderPath: any;
    allowDragAndDrop = true;
    FULLPATH: any;
    Foldername: any;
    public contextMenuSettings = {
        visible: false // Completely disable the right-click menu
      };

      public detailsViewSettings: object = {
        showFileDetails: true,
      };
    TypeNameData: any;
    firstFolderNode: any=[];
    //public view: string;

    constructor(
        private cdf: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private dialog: MatDialog,
        private toastr: ToastrService,
        private behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        private TableColumnsService: TableColumnsService,
        private SortingbehaviorService: SortingBehaviourService,
        private titleService: Title, private _hyperlink: HyperlinkNavigationService,
        private renderer: Renderer2,
        public toolbarServiceService: ToolbarServiceService,
        private el: ElementRef

    ) {

        this.toolbarSettings = {
            items: ['Refresh', 'View', 'Details'],
          };

        this._hyperlink.setColorSetting();
        this._hyperlink.setColorSettingWithHyperlink()
        this.behaviorService.ColorSystemSetting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data => {
            this.COLOR = data
        })

        this.behaviorService.ColorSystemSettingHyperLink$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data => {
            this.HyperlinkColor = data
        })

        this.behaviorService.LoadingResultData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
            if(data == false){
                hideSpinner(document.getElementById('containerdata'));
            }else{
                showSpinner(document.getElementById('containerdata'));
            }
        })

        this.addFilter = {
            OPERATION: "",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: "",
        };


        this.behaviorService.DocumentRegisterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                this.isOpenDesktopWord = false;
                this.isDocRegData = 'Notempty';
                this.DocRegData = result;
                if (this.DocRegData.type == 'docx' || this.DocRegData.type == 'doc'
                    || this.DocRegData.type == 'dotx' || this.DocRegData.type == 'dot'
                    || this.DocRegData.type == 'docm' || this.DocRegData.type == 'dotm') {
                    this.isOpenDesktopWord = true;
                }
            } else {
                this.isDocRegData = 'empty';
            }
        });






        // this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
        //     const materIDofTab = window.name.split("_");
        //     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        //     this.mattersData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        //     if (this.mattersData) {
        //         // localStorage.setItem('set_active_matters', JSON.stringify(this.mattersData));
        //     } else {
        //         if (result) {
        //             this.mattersData = result;
        //             localStorage.setItem(this.windowNameId, JSON.stringify(this.mattersData))
        //         } else {
        //             this.mattersData = JSON.parse(localStorage.getItem('set_active_matters'));
        //             localStorage.setItem(this.windowNameId, JSON.stringify(this.mattersData))
        //         }
        //     }
        // });
        this.behaviorService.clickMaterFromTimeLine$
            .pipe(
                tap(result => {
                    if (result) {
                        this.mattersData = result;
                    } else {
                        this.mattersData = JSON.parse(localStorage.getItem(this.windowNameId || 'set_active_matters')) || result;
                        localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.mattersData));
                    }
                })
            )
            .subscribe();
        localStorage.setItem('istrackid', 'DocumentRegisterComponent');
        this.SortingbehaviorService.documentRegisterSorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.sortingDefaultState = result;
                localStorage.setItem('documnet_register_screen_sorting', JSON.stringify(result));
            } else {
                this.sortingDefaultState = JSON.parse(localStorage.getItem('documnet_register_screen_sorting'))
            }
        });

        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'DocumentRegisterComponent' && result['click'] && result['data']['document']) {
                if (!localStorage.getItem('matterdoc_list_columns')) {
                    this.getTableFilter();
                    this.getFilter();
                }
                // this.LoadData();
                this.LoadData2(this.lastNetDriveFolderFilter);
            }
        });
        this.behaviorService.SetDocumentRegisterFolderClickData(null);
        this.behaviorService.clickMaterFromTimeLine$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            // let headerTitle = `Matters & Details (${this.currentMatter.SHORTNAME}) - ${this.activatedRoute.snapshot.routeConfig.path}`
             let headerTitle = (result && result != undefined) ? result : this.currentUser
            // this.titleService.setTitle((this.currentUser?.USEV11UI == true) ? headerTitle + '-' + 'Documents' : 'Document Register (' + headerTitle + ')');
            let MATTERDETAILSTABTEXT = JSON.parse(localStorage.getItem("set_preferences"));
             if(MATTERDETAILSTABTEXT.MATTERDETAILSTABTEXT !=undefined && MATTERDETAILSTABTEXT.MATTERDETAILSTABTEXT == 'Matter Number'){
                this.titleService.setTitle(headerTitle.SHORTNAME + '-' + 'Documents');
             }else{
                this.titleService.setTitle(headerTitle.CONTACTNAME + '-' + 'Documents');
             }
            //let headerTitle = result ? result.SHORTNAME : this.currentUser.SHORTNAME;
            //this.titleService.setTitle(headerTitle + '-' + 'Documents');
        })
        // this.titleService.setTitle((this.currentUser?.USEV11UI==true) ? this.mattersData.SHORTNAME + '-'+'Documents' :'Document Register (' + this.mattersData.SHORTNAME + ')');
   
        this.behaviorService.DocumentRegisterNewData$.subscribe(data=>{
            if(data !=null){
                //console.log('data',data);
                this.resultData.forEach((element,i) => {
                    if(element.id == data.id){
                        this.resultData.splice(i,1);
                    }
                });
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

        this.detailsViewSettings = {
            columns: [
              { field: 'name', headerText: 'Name', minWidth: 50, width: 'auto', customAttributes: { class: 'e-fe-grid-name' } },
              { field: 'dateModified', headerText: 'Modified', type: 'date', format: 'MMMM dd, yyyy HH:mm', width: '170' },
              { field: 'USERID', headerText: 'User Id', type: 'text', width: '120' },
              { field: 'DOCUMENTAUTHOR', headerText: 'Author', type: 'text', width: '150' },
              { field: 'TEMPLATENAME', headerText: 'Template used', type: 'text', width: '170' }

              
              // Exclude the 'size' field from here
            ]
          };
        this.dialogSettings = {
            showDeleteConfirmation: true, // Disable the delete confirmation
          };

        this.permission = {
            "copy": false,
            "download": false,
            "write": false,
            "writeContents": false,
            "read": true,
            "upload": false,
            "message": ""
        };

        this.behaviorService.permissionCheckforHeight$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data => {
            this.class = data
        })

        this.allowDragAndDrop = true;


        this.FolderDocumentList = [];
        if (this.sortingDefaultState) {
            this.sortactive = this.sortingDefaultState.active;
            this.sortDirection = this.sortingDefaultState.direction;
        } else {
            this.sortactive = "";
            this.sortDirection = "";
        }

        this.documentform = this._formBuilder.group({
            matter: [''],
            Client: [''],
            search: [''],
            foldervalue: [''],
            showfolder: [''],
            FILTERS: new FormArray([]),
            FILTERSLIST: new FormArray([]),
            MAINFILTER: [""],
        });
        this.getTableFilter();
        this.getFilter();
       // let mattersData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        this.SortingbehaviorService.DocumentRegisterFilterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.documentform.controls['search'].setValue(result.SearchString);
                this.mattersData = result.materdata;
                if (result.id != 'All') {
                    this.lastSubFolder.id = "";
                    this.lastSubFolder.text = "";
                    this.lastSubFolder = result.lastSubFolder;
                } else {
                    this.lastSubFolder.id = result.id;
                    this.lastSubFolder.text = result.text;
                }
            }
        });
        this.activeMATTERGUID = this.mattersData.MATTERGUID;
        this.documentform.controls['matter'].setValue(this.mattersData.SHORTNAME);
        this.documentform.controls['Client'].setValue(this.mattersData.CONTACTNAME);
        // this.LoadData();
        this.LoadData2({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' });
        this.setDefaultAdvanceFilter();
        this.view = 'Details';
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to refresh the DOCREGTab
     */
    refreshDOCREGTab() {
        this.lastNetDriveFolderFilter.DOCUMENTGUID = this.DocRegData.DOCUMENTGUID
        this.LoadData2(this.lastNetDriveFolderFilter);
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
       
    }

    /**
     * This function is used to getTableFilter data.
     */
    getTableFilter() {
        let matterDocListColumns: any = JSON.parse(localStorage.getItem('matterdoc_list_columns'));
        if (matterDocListColumns && matterDocListColumns != null) {
            let data = this.TableColumnsService.filtertableColum(matterDocListColumns.ColumnsObj);
            this.displayedColumns = matterDocListColumns.displayedColumns;
            this.ColumnsObj = matterDocListColumns.ColumnsObj;
            this.tempColobj = data.tempColobj;
            this.dateColFilter = data.dateCol;
        } else {
            this.TableColumnsService.getTableFilter('Matter Documents', '').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    let data = this.TableColumnsService.filtertableColum(response.DATA.RECORDS);
                    this.displayedColumns = data.showcol;
                    this.ColumnsObj = data.colobj;
                    this.tempColobj = data.tempColobj;
                    this.dateColFilter = data.dateCol;
                    localStorage.setItem('matterdoc_list_columns', JSON.stringify({ "ColumnsObj": data.colobj, 'displayedColumns': data.showcol }));
                }
            }, error => {
                this.toastr.error(error);
            });
        }
    }

    /**
     * This function is used to OnResizing the table data value.
     */
    onResizing(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + 'px';
            const columnElts = document.getElementsByClassName('mat-column-' + columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
            const indexData = this.ColumnsObj.findIndex(col => col.COLUMNID === columnName);
            this.ColumnsObj[indexData]['WIDTH'] = event.rectangle.width;
            localStorage.setItem('matterdoc_list_columns', JSON.stringify({ "ColumnsObj": this.ColumnsObj, 'displayedColumns': this.displayedColumns }));

        }
    }

    /**
     * This function is used to Resized the End data value.
     */
    onResizeEnd(event: ResizeEvent, columnName): void {
        this.TableColumnsService.SaveWidthData(this.ColumnsObj, "Matter Documents", "");
    }

    /**
     * Thsi function is used to set the Default Width.  
     */
    setDefaultWidth(displayedColumns, timeout) {
        setTimeout(() => {
            displayedColumns.forEach(element => {
                let temWidth = this.tempColobj[element]['WIDTH'];
                const cssValue = temWidth + 'px';
                const columnElts = document.getElementsByClassName('mat-column-' + element);
                for (let i = 0; i < columnElts.length; i++) {
                    const currentEl = columnElts[i] as HTMLDivElement;
                    currentEl.style.visibility = 'inherit';
                    currentEl.style.width = cssValue;
                }
            });
        }, timeout);
    }

    /**
     * This function is used to onPaginate change
     */
    onPaginateChange(event) {
        this.setDefaultWidth(this.displayedColumns, 0);
        this.pageSize.document_register = event.pageSize;
        localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
    }

    /**
     * This function is used to load the data 2. 
     */
    LoadData2(postData) {
        this.isLoadingResults = true;
        showSpinner(document.getElementById('containerdata'));
        this.FolderDocumentList = [];
        this.lastNetDriveFolderFilter = postData;
        postData.MATTERGUID = this.activeMATTERGUID;
        //console.log('LastFolder...........>>>',this.lastNetDriveFolderFilter);

        // New CHEANGES ARE DONE
       // postData.FOLDERNAME='';
        //postData.DOCUMENTGUID = this.DocRegData.DOCUMENTGUID
        let Data=postData;
        this._mainAPiServiceService.getSetData({"Action": "syncfusiongetmatterfolders",Data}, 'cloud-storage').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.firstFolderNode=res.DATA.FOLDERS[0];
                // Bad path comes to the API
                res.DATA.FOLDERS.forEach(element => {
                    if(element.parentId == null){
                        element.filterPath='\\';
                    }
                    if(element?.FULLPATH && element?.FULLPATH !=undefined){
                        const lastIndex = element?.FULLPATH.lastIndexOf('\\');
                        if (lastIndex !== -1) {
                            // Retain everything up to the last backslash
                           // element.filterPath=element?.FULLPATH.substring(0, lastIndex + 1);
                          } 
                       // element.filterPath=element.FULLPATH.replace("<MatterFolder>", "");
                    }
                    if(element?.filterPath && element?.filterPath !=undefined && element?.filterPath != '<MatterFolder>' && element.filterPath !='\\'){
                        element.filterPath = element.filterPath.split("/").join("\\");
                        element.filterPath = element.filterPath+'\\'
                        element.FULLPATH = element.FULLPATH.split("/").join("\\");
                    }
                });
                this.FolderData = res.DATA.FOLDERS;
                this.FolderData.forEach(element => {
                    if(element.type == 'folder'){
                       element.foldertype='folder'
                    }
                });
                //console.log(this.FolderData);
               // this.resultData = [].slice.call(this.FolderData) as { [key: string]: Object }[];
                if (Object.keys(this.pushFolder).length > 0) {
                    this.FolderDocumentList.unshift({ FOLDERNAME: `.. Go up one level`, foldertype: "Go_Back", is_folder: true });
                }
                res.DATA.FOLDERS.forEach(e => {
                    e.is_folder = true;
                    e.foldertype = 'folder';
                    delete e.FOLDERS;
                    this.FolderDocumentList.push(e);
                });
                this.isLoadingResults = false;
                //console.log('res.DATA.FOLDERS',res.DATA.FOLDERS);
                //console.log('this.FolderDocumentList',this.FolderDocumentList);
            } else {
                this.isLoadingResults = false;
                hideSpinner(document.getElementById('containerdata'));
            }
            this.LoadData();
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
            hideSpinner(document.getElementById('containerdata'));
        });
    }

    /**
     * This function is used to load the data value.
     */
    LoadData() {
        let filterVal: any = {};
        let DocumentList: any = [];
        if (this.FolderDocumentList) {
            this.FolderDocumentList.forEach(element => {
                DocumentList.push(element)
            });
        }

        //console.log('lastFolder data value',this.lastSubFolder);
      //  if (this.lastSubFolder == "All") {
      //      filterVal = { 'SearchString': this.f.search.value, 'MATTERGUID': this.activeMATTERGUID }
      //      let ForDataFilter = { 'SearchString': this.f.search.value, 'matterData': this.mattersData, 'lastSubFolder': 'All' }
      //     this.SortingbehaviorService.SetTransactionFilterData(ForDataFilter);

      //   } else {
           // filterVal = { 'SearchString': this.f.search.value, 'MATTERGUID': this.activeMATTERGUID, FULLPATH: this.LastFolderFullPath, FOLDERNAME: this.lastSubFolder };
           // let ForDataFilter = { 'SearchString': this.f.search.value, 'matterData': this.mattersData, 'lastSubFolder': '', 'id': this.LastFolderFullPath, 'text': this.lastSubFolder };
             filterVal = { 'SearchString': this.f.search.value, 'MATTERGUID': this.activeMATTERGUID, FULLPATH: this.FULLPATH, FOLDERNAME: this.Foldername };
            let ForDataFilter = { 'SearchString': this.f.search.value, 'matterData': this.mattersData, 'lastSubFolder': '', 'id': this.LastFolderFullPath, 'text': this.Foldername };
           this.SortingbehaviorService.SetTransactionFilterData(ForDataFilter);
      //   }
        const advanceFilterPayload = this.loadAdvanceFilterPayload();
        // this.filterVals.AdvancedFilters = advanceFilterPayload ;
        if (
            advanceFilterPayload == "" ||
            advanceFilterPayload == undefined ||
            advanceFilterPayload == null
        ) {
            delete filterVal.AdvancedFilters;
        } else {
            filterVal.AdvancedFilters = advanceFilterPayload;
        }
        this.isLoadingResults = true;
        filterVal["INCLUDESUBFOLDERS"] = this.includeSubFolder;
        const AdvancedFilters = filterVal.AdvancedFilters ? filterVal.AdvancedFilters : [];
        // let Payload: any = {
        //     "Action": "GetData",
        //     Filters: filterVal,
        //     AdvancedFilters: AdvancedFilters
        // };

           let Payload: any = {
            "Action": "GetDataSyncFusion",
             Filters: filterVal,
             AdvancedFilters: AdvancedFilters
         };
        if (AdvancedFilters.length == 0) {
            delete Payload.AdvancedFilters
        }
        delete Payload.Filters.AdvancedFilters;
        this._mainAPiServiceService.getSetData(Payload, 'document').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.behaviorService.DocumentRegisterData(null);
                if (res.DATA.RECORDS[0]) {
                    showSpinner(document.getElementById('containerdata'));
                    // DOCUMENTNAME
                    const datePipe = new DatePipe('en-US');

                    res.DATA.RECORDS.forEach((element,i) => {
                        if (element.documentName) {
                            // Extract the file type from the document name
                            const docType = element.documentName.split('.').pop();
                            element.type = docType;
                            if(element.type == 'png'){
                                element.imageUrl='/assets/icons/syncfusion_icons/Png-icon.svg'
                            }else if(element.type == 'jpg'){
                                element.imageUrl='/assets/icons/syncfusion_icons/Jpg-icon.svg'
                            }
                        }
                    
                        if (element?.GENERATEDATEANDTIME) {
                            const dateParts = element?.GENERATEDATEANDTIME.match(/(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{1,2}):(\d{2})(AM|PM)/i);
  if (dateParts) {
    let [, month, day, year, hours, minutes, period]: any = dateParts;

    // Convert to a Date object
    month = parseInt(month, 10) - 1; // Convert month to zero-based index
    day = parseInt(day, 10);
    year = parseInt(year, 10);
    hours = parseInt(hours, 10) % 12 + (period.toUpperCase() === 'PM' ? 12 : 0);
    minutes = parseInt(minutes, 10);

    const date = new Date(year, month, day, hours, minutes);

    // Format the date into the desired format using DatePipe
    const formattedDate = datePipe.transform(date, 'MMMM dd, yyyy hh:mm:ss');
                                element.dateModified = formattedDate;
                            }
                        }
                    
                        // Add the element to DocumentList
                        DocumentList.push(element);
                    });
                    
                    // New Changes
                    let data = [this.FolderData, ...res.DATA.RECORDS];
                    const flatArray = data.flat();
                    flatArray.forEach((item: { [key: string]: Object }) => {
                        // Find the existing item in resultData based on the id
                        const existingItemIndex = this.resultData.findIndex(existingItem => existingItem.id === item.id);                      
                        if (existingItemIndex === -1) {
                          this.resultData.push(item);
                        } else {
                           // If item exists, update the name (or any other property you need to update)
                          if(this.resultData[existingItemIndex].type != 'folder'){

                              this.resultData[existingItemIndex].name = item.name;
                          }
                        }
                      });
                    const idMap = new Map();

                    // Create a map for quick lookup
                    this.resultData.forEach(item => {
                      idMap.set(item.id, item);
                    });
                    
                    this.resultData.forEach(item => {
                      if (item.parentId && idMap.has(item.parentId)) {
                        const parent = idMap.get(item.parentId);
                        
                        if (parent) {
                          // If parent is a folder
                          if (parent.type === "folder") {
                            // Check if the child (current item) is not a file
                            if (item.isFile === false) {
                              parent.hasChild = true;  // Set hasChild to true if the child is not a file
                            } else {
                            //  parent.hasChild = false; // Set hasChild to false if the child is a file
                            }
                          } else {
                            // If the parent is not a folder, set hasChild to false
                           // parent.hasChild = false;
                          }
                        }
                      }
                    });

                    // Reassign to trigger change detection
                    this.resultData = [...this.resultData]; 
                    console.log('resultData',this.resultData);
                    hideSpinner(document.getElementById('containerdata'));

                    setTimeout(() => {
                        document.querySelectorAll('.e-navigation').forEach((el) => {
                          (el as HTMLElement).style.flexBasis = '16%';
                          (el as HTMLElement).style.order = '0';
                        });
                      }, 100);
                    // this.resultData= [
                    //     {
                    //       "hasChild": true,
                    //       "isFile": false,
                    //       "type": "folder",
                    //       "FULLPATH": "<MatterFolder>",
                    //       "name": "LEA039",
                    //       "webUrl": "https://silqware.sharepoint.com/Shared%20Documents/SILQ/Matters/L/LEA039",
                    //       "id": "<MatterFolder>",
                    //       "parentId": null,
                    //       "filterPath": "\\",
                    //       "foldertype": "folder",
                    //       "is_folder": true
                         
                    //     },
                    //     {
                    //       "filterPath": "<MatterFolder>",
                    //       "hasChild": true,
                    //       "isFile": false,
                    //       "name": "abc test",
                    //       "type": "folder",
                    //       "FULLPATH": "<MatterFolder>abc test",
                    //       "webUrl": "https://silqware.sharepoint.com/Shared%20Documents/SILQ/Matters/L/LEA039/abc%20test",
                    //       "id": "<MatterFolder>abc test",
                    //       "parentId": "<MatterFolder>",
                    //       "foldertype": "folder",
                    //       "is_folder": true
                         
                    //     },
                        
                        
                    //     {
                    //       "filterPath": "<MatterFolder>abc test",
                    //       "hasChild": true,
                    //       "isFile": false,
                    //       "name": "ddemo1",
                    //       "type": "folder",
                    //       "FULLPATH": "<MatterFolder>abc test/ddemo1",
                    //       "webUrl": "https://silqware.sharepoint.com/Shared%20Documents/SILQ/Matters/L/LEA039/abc%20test/ddemo1",
                    //       "id": "<MatterFolder>abc test/ddemo1",
                    //       "parentId": "<MatterFolder>abc test",
                    //       "foldertype": "folder",
                    //       "is_folder": true
                         
                    //     },
                        
                    //     {
                    //       "filterPath": "<MatterFolder>abc test/ddemo1",
                    //       "hasChild": false,
                    //       "isFile": false,
                    //       "name": "ddemo2",
                    //       "type": "folder",
                    //       "FULLPATH": "<MatterFolder>abc test/ddemo1/ddemo2",
                    //       "webUrl": "https://silqware.sharepoint.com/Shared%20Documents/SILQ/Matters/L/LEA039/abc%20test/ddemo1/ddemo2",
                    //       "id": "<MatterFolder>abc test/ddemo1/ddemo2",
                    //       "parentId": "<MatterFolder>abc test/ddemo1",
                    //       "foldertype": "folder",
                    //       "is_folder": true
                    //     }
                    //   ]
                    // New changes are done
                    
                    this.RowClick(res.DATA.RECORDS[0]);
                    this.highlightedRows = res.DATA.RECORDS[0].DOCUMENTGUID;
                    this.DocumentAllData = new MatTableDataSource(DocumentList);
                    this.sortingDate();
                    // this.DocumentAllData.sort = this.sort;
                    this.isDisplay = false;
                } else {
                    //res.DATA.RECORDS[0] = this.firstFolderNode;
                    this.toastr.error("No Documents Available");
                    showSpinner(document.getElementById('containerdata'));
                    let data = [this.FolderData];
                    const flatArray = data.flat();
                    flatArray.forEach((item: { [key: string]: Object }) => {
                        // Find the existing item in resultData based on the id
                        const existingItemIndex = this.resultData.findIndex(existingItem => existingItem.id === item.id);                      
                        if (existingItemIndex === -1) {
                          this.resultData.push(item);
                        } else {
                           // If item exists, update the name (or any other property you need to update)
                          if(this.resultData[existingItemIndex].type != 'folder'){

                              this.resultData[existingItemIndex].name = item.name;
                          }
                        }
                      });
                    const idMap = new Map();

                    // Create a map for quick lookup
                    this.resultData.forEach(item => {
                      idMap.set(item.id, item);
                    });
                    
                    this.resultData.forEach(item => {
                      if (item.parentId && idMap.has(item.parentId)) {
                        const parent = idMap.get(item.parentId);
                        
                        if (parent) {
                          // If parent is a folder
                          if (parent.type === "folder") {
                            // Check if the child (current item) is not a file
                            if (item.isFile === false) {
                              parent.hasChild = true;  // Set hasChild to true if the child is not a file
                            } else {
                            //  parent.hasChild = false; // Set hasChild to false if the child is a file
                            }
                          } else {
                            // If the parent is not a folder, set hasChild to false
                           // parent.hasChild = false;
                          }
                        }
                      }
                    });

                    // Reassign to trigger change detection
                    this.resultData = [...this.resultData]; 
                    console.log('resultData',this.resultData);
                    hideSpinner(document.getElementById('containerdata'));
                    this.DocumentAllData = new MatTableDataSource(DocumentList);
                    this.isDisplay = false;
                }
                //this.behaviorService.DocumentRegisterData(res.DATA.DOCUMENTS[0]);
                this.showFolder = '';
                Object.keys(this.pushFolder).forEach(key => {
                    this.showFolder += this.pushFolder[key] + '/';
                });
                // this.sendSaveFolder="";
                this.DocumentAllData.paginator = this.paginator;
                this.isLoadingResults = false;
                this.setDefaultWidth(this.displayedColumns, 500);
            }
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
            hideSpinner(document.getElementById('containerdata'));

        });
        this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
    }
   
    /**
     * This function is used to Document folder set 
     */
    DcoumentFloder() {
        const dialogConfig = new MatDialogConfig();
        let mattersData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        const dialogRef = this.dialog.open(MatterPopupComponent, {
            width: '100%',
            disableClose: true,
            data: { action: 'edit', 'matterGuid': mattersData.MATTERGUID }
        });
        dialogRef.afterClosed().subscribe(result => { });
    }
    
    /**
     * This function is used to select the client value. 
     */
    SelectClient() {
        if (!localStorage.getItem('contactGuid')) {
            this.toastr.error("Please Select Contact");
        } else {
            const dialogRef = this.dialog.open(ContactDialogComponent, { disableClose: true, data: { action: 'edit' } });
            dialogRef.afterClosed().subscribe(result => {
                if (result)
                    $('#refreshContactTab').click();
            });
        }
    }

    /**
     * This functionis used to get the Document Dialog.
     */
    DocumentDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '100%';
        dialogConfig.disableClose = true;
        dialogConfig.data = { 'data': this.ColumnsObj, 'type': 'Matter Documents', 'list': '' };
        //open pop-up
        const dialogRef = this.dialog.open(SortingDialogComponent, dialogConfig);
        //Save button click
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.displayedColumns = result.columObj;
                this.ColumnsObj = result.columnameObj;
                this.tempColobj = result.tempColobj;
                this.dateColFilter = result.dateCol;
                localStorage.setItem('matterdoc_list_columns', JSON.stringify({ 'displayedColumns': result.columObj, "ColumnsObj": result.columnameObj }));
                if (!result.columObj) {
                    this.DocumentAllData = new MatTableDataSource([]);
                    this.DocumentAllData.paginator = this.paginator;
                    this.DocumentAllData.sort = this.sort;
                    this.isDisplay = true;
                } else {
                    let filterVal = { 'SearchString': this.f.search.value, 'MATTERGUID': this.activeMATTERGUID };
                    this.LoadData2(this.lastNetDriveFolderFilter);
                }
            }
        });
    }
    get f() {
        return this.documentform.controls;
    }

    /**
     * This function is used to sorting the Date data value.
     */
    sortingDate() {
        this.DocumentAllData.sortingDataAccessor = (item, property) => {
            let FildeValue = this.dateColFilter;
            if (FildeValue.includes(property)) {
                if (item[property] && (property == 'GENERATEDATEANDTIME')) {
                    let momentDate = moment(moment(item[property], 'DD/MM/YYYY hh:mm A')).format('YYYY-MM-DD HH:mm:ss');
                    let timestamp = Date.parse(momentDate);
                    return timestamp / 1000;
                } else if (item[property] && (property != 'GENERATEDATEANDTIME')) {
                    let tempDate = item[property].split("/");
                    let Sd = new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]);
                    let newDate = new Date(Sd);
                    return newDate;
                }
                if ("folder" == item.foldertype || "Go_Back" == item.foldertype) {
                    if ("desc" == this.sort.direction) return '\uFFFF' + '\uFFFF' + '\uFFFF';
                    return '\u0000' + '\u0000' + '\u0000';
                }
                return item[property];
            } else {
                if ("folder" == item.foldertype || "Go_Back" == item.foldertype) {
                    if ("desc" == this.sort.direction) return '\uFFFF' + '\uFFFF' + '\uFFFF';
                    return '\u0000' + '\u0000' + '\u0000';
                } else if (null == item[property] || '' == item[property]) {
                    if ("desc" == this.sort.direction) return '\u0000' + '\u0000' + '\u0000';
                    return '\uFFFF' + '\uFFFF' + '\uFFFF';
                }
                return item[property];
            }
        }
        // proper shorting for date
        this.DocumentAllData.sort = this.sort;
    }

    /**
     * This function is used to sort the Data. 
     */
    sortData(s) {
        this.SortingbehaviorService.documentRegisterSorting(s);
        this.setDefaultWidth(this.displayedColumns, 0);
    }

    
    /**
     * This function is used to search the filter.
     */
    FilterSearch(filterValue: any) {
        // this.DocumentAllData.filter = filterValue;
    }


    /**
     * This function is used to change the folder data 
     */
    FloderChnage($event: SelectEventArgs) {
        this.lastSubFolder = $event.itemData;
        this.LoadData();
    }


    /**
     * This function is used to click on the Document
     */
    clickDoc(value) {
    }

    /**
     * This function is used to click on the row
     */
    RowClick(row) {
        this.behaviorService.DocumentRegisterData(row);
    }

    /**
     * This function is used to onSearch the data value.
     */
    onSearch(searchFilter: any) {
        this.Searchvalue = this.f.search.value
        if (searchFilter == 'Empty') {

            this.documentform.controls['search'].setValue('');
            this.LoadData2(this.lastNetDriveFolderFilter);
        }
        if (searchFilter['key'] === "Enter" || searchFilter == 'Enter') {
            let filterVal = { 'SearchString': this.f.search.value, 'MATTERGUID': this.activeMATTERGUID };
            this.Searchvalue = this.f.search.value
            // this.LoadData();
            this.LoadData2(this.lastNetDriveFolderFilter);
        }
    }

    /**
     * This function is used to onFile change.
     */
    onFilesChange(file) {
        this.fileList.push(file);
        this.SaveRates(file);
    }

    /**
     * This functionis used to Format the AM to PM
     */
    formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    /**
     * This function is used to close the popup
     */
    closePopup() {
        this.fileList = [];
    }

    /**
     * This function is used to Save the Rates. 
     */
    SaveRates(file: any) {
        this.isUplodedFileListShow = true;
        showSpinner(document.getElementById('containerdata'));
        let date = new Date(file.lastModified);
        let reader = new FileReader();
        reader.onload = (event: any) => {
            let details: any = {
                GENERATEDATE: date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear(),
                GENERATETIME: this.formatAMPM(date),
                CONTEXT: "Matter",
                DOCUMENTCLASS: "External Document",
                DESCRIPTION: file.name,
                DRAFTSTATUS: 'Complete',
                DOCUMENTTYPE: 'Electronic Document',
                DOCUMENTAUTHOR: this.logedInUser.UserName,
                DOCUMENTNAME: file.name,
                MATTERGUID: this.activeMATTERGUID,
                FILEDATA: event.target.result,
                FOLDERNAME: this.lastNetDriveFolderFilter.FOLDERNAME
            };
            let postData: any = { FORMACTION: 'insert', VALIDATEONLY: true, Data: details };
            this._mainAPiServiceService.getSetData(postData, 'SetDocument').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                postData.VALIDATEONLY = true;
                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                    this.checkValidation(response.DATA.VALIDATIONS, postData);
                } else if (response.CODE == 451 && response.STATUS == 'warning') {
                    this.checkValidation(response.DATA.VALIDATIONS, postData);
                } else if (response.CODE == 450 && response.STATUS == 'error') {
                    this.checkValidation(response.DATA.VALIDATIONS, postData);
                }
            }, error => {
                this.toastr.error(error);
                hideSpinner(document.getElementById('containerdata'));
            });
        };
        reader.readAsDataURL(file);
    }

    /**
     * Thias function is used to check the validation.
     */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.saveDocument(details);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.saveDocument(details);
        }
    }

    /**
     * This function is used to save the Document data value. 
     */
    saveDocument(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'SetDocument').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.isUplodedFileListShow = false;
                hideSpinner(document.getElementById('containerdata'));
                this.toastr.success('Document save successfully');
                // this.LoadData();
                this.LoadData2(this.lastNetDriveFolderFilter);

            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                showSpinner(document.getElementById('containerdata'));
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                showSpinner(document.getElementById('containerdata'));
            }
        }, error => {
            this.toastr.error(error);
            showSpinner(document.getElementById('containerdata'));
        });
    }

    /**
     * This function is used to change the Group
     */
    isGroup(index, item): boolean {
        return item.is_folder;
    }

    /**
     * This function is used to double click
     */
    doubleClick(row,alldata?) {
        //console.log('row data value',row);
        if (row.foldertype == "folder") {
            this.FolderDataArray.push(row);
            this.behaviorService.SetDocumentRegisterFolderClickData(this.FolderDataArray);
            this.sendSaveFolder = row.FULLPATH;
            localStorage.setItem('sendSaveFolder', row.FULLPATH);
            this.pushFolder[row.FULLPATH] = row.FOLDERNAME;
            this.LastFolderFullPath = Object.keys(this.pushFolder)[Object.keys(this.pushFolder).length - 1];

            this.lastSubFolder = this.pushFolder[this.LastFolderFullPath];
            setTimeout(() => {
              //  this.LoadData2({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: this.LastFolderFullPath });
                this.LoadData2({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: this.FULLPATH });
            }, 500);
        } else if (row.foldertype == "Go_Back") {
            this.FolderDataArray.pop();
            this.behaviorService.SetDocumentRegisterFolderClickData(this.FolderDataArray);
            delete this.pushFolder[this.LastFolderFullPath];
            setTimeout(() => {
                if (Object.keys(this.pushFolder).length > 0) {
                    this.LastFolderFullPath = Object.keys(this.pushFolder)[Object.keys(this.pushFolder).length - 1];
                    this.lastSubFolder = this.pushFolder[this.LastFolderFullPath];
                   // this.LoadData2({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: this.LastFolderFullPath });
                   this.LoadData2({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: this.FULLPATH });
                } else {
                    this.lastSubFolder = "All";
                    this.LoadData2({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' });
                }
            }, 500);
        }
    }

    /**
     * This function is used to open the Matter
     */
    OpenMatter(Type: any, page: any) {

        this.selectedMatterTab = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        this.ApiRepoParm1 = Type;
        this.ApiRepoParm2 = page;
        this.isGenrateReport = true;
        let sendData: any = {}
        // let APIURL = "NetDriveGetDocument";
        let APIURL = "cloud-storage";
        sendData =
        {
            Action: 'GetDocument',
            Data:{ FileType: Type, MATTERGUID: this.DocRegData.MATTERGUID, DocumentName: this.DocRegData.DOCUMENTNAME }
        }

        this._mainAPiServiceService.getSetData(sendData, APIURL).pipe(takeUntil(this._unsubscribeAll$)).subscribe(DriveGetDocument => {
            if (DriveGetDocument.CODE == 200 && DriveGetDocument.STATUS == "success") {
                this.toastr.success('Document Generate successfully');
                this.isGenrateReport = false;

                window.open(DriveGetDocument.DATA.DOCUMENTS[0].DRIVEFILEWEBURL, '_blank');
            } else if (DriveGetDocument.CODE == 432) {
                let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
                this.isGenrateReport = false;
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentUser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                    }
                    this._mainAPiServiceService
                    .getSetData(payload, 'oauth-connections')
                    .subscribe((response) => {
                        if(response.CODE == 200 && response.STATUS == "success") {
                            window.open(
                                response.DATA[0].AuthorizationUrl,
                                "targetWindow",
                                `toolbar=no,
                                location=no,
                                status=no,
                                menubar=no,
                                scrollbars=yes,
                                resizable=yes,
                                width=800,
                                height=600`
                            );
                        }
                    });
            } else {
                this.isGenrateReport = false;
            }
        }, error => {
            this.isGenrateReport = false;
            this.toastr.error(error);
        });
    }

    /**
     * Thias function is used to back to filter go 
     */
    backToFilter(id) {
        $("#" + id).removeClass("active");
        setTimeout(() => {
            $(".mat-menu-content").removeClass("active");
            $(".mat-menu-content").removeClass("mat-menu-content_height");
        }, 50);

        if (this.FILTERS) {
            this.FILTERSLIST = this.documentform.get(
                "FILTERSLIST"
            ) as FormArray;
            this.FILTERSLIST.reset();
        }
    }

    /**
     * This function is used to reset the Filter data value.
     */
    resetFilter() {
        // reset FILTERAPPLY value
        this.optionalFilterList.forEach((filter, index) => {
            filter.FILTERAPPLY = false;
        });
        // ends here ~ reset FILTERAPPLY value
        localStorage.removeItem("Document_Advance_Filter");
        this.saveAdvanceFilter = [];
        this.optionalFilters = [];
        this.optionalDateFilterPayload = [];
        if (this.FILTERS) {
            this.FILTERS.reset();
            // this.FILTERSLIST.reset();
        }
        setTimeout(() => {
            if (this.lastNetDriveFolderFilter.AdvancedFilters) {
                delete this.lastNetDriveFolderFilter.AdvancedFilterss
            }
            this.LoadData2(this.lastNetDriveFolderFilter);
        }, 500);

        setTimeout(() => {
            this.detectedChangeRef = Math.random()
        }, 500)
    }

    /**
     * This function is used to preventDefault the data value.
     */
    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * This functionis used to get the filter data value.
     */
    getFilter() {
        let opFilterList = JSON.parse(
            localStorage.getItem("matterdoc_list_columns")
        );
        const filterData = this.TableColumnsService.getDynamicFilter(
            opFilterList,
            this.optionalFilterList,
            "matterdoc_list_columns"
        );
        //  this.defaultSetFilter();
        this.refreshFilterList();
        setTimeout(() => {
            this.detectedChangeRef = Math.random();
        }, 500)
    }

    /**
     * This functionm is used to refresh the Filter list value.
     */
    refreshFilterList() {
        //AdvanceFilter Refresh
        this.totalApplicableFilterCount = 0;
        const advDynamicFilter = JSON.parse(
            localStorage.getItem("Document_Advance_Filter")
        );
        this.optionalFilterList.map((x) => {
            if (x.FILTERTYPE != "HIDDEN") {
                this.totalApplicableFilterCount += 1;
            }

            if (
                advDynamicFilter &&
                advDynamicFilter.findIndex((e) => e.COLUMNID == x.COLUMNID) !==
                -1
            ) {
                x.FILTERAPPLY = true;
            }
        });
    }

    /**
     * This function is used to set the active current filter data value.
     */
    activeCurrentFilter(id, data?: any) {
        $("#" + id).addClass("active");
        setTimeout(() => {
            $(".mat-menu-content").addClass("active");
            $(".mat-menu-content").addClass("mat-menu-content_height");
        }, 200);

        //this.matterFilterForm.controls['MAINFILTER'].setValue(data.FILTERTYPE);
        this.selectedFilterIs = "";
        this.activeOptionalFilter = data;
        this.documentform.controls["MAINFILTER"].reset();
    }
    activeFilter = FilterSearchOptions.activeFilter;

    unbuildWorkFilter = FilterSearchOptions.unbuildWorkFilter;

    dateFilter = FilterSearchOptions.dateFilter;

    currencyFilter = FilterSearchOptions.currencyFilter;

    manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;
    @ViewChild("operationFilter", { static: false }) operationFilter: MatSelect;

    /**
     * This function is used to add the new Filter data value
     */
    addNewFilter(event) {
        setTimeout(() => {
            // this.showFilterOption = true;
            this.operationFilter.options.first.select();
        }, 200);
        if (this.FILTERSLIST) {
            this.FILTERSLIST.controls.splice(0, 1);
            //this.FILTERSLIST.reset();
        }

        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }

        this._filterList = [event];
        this.addFilter.FILTERTYPE = event.FILTERTYPE;

        // OPTIONAL FILTER
        this.FILTERSLIST = this.documentform.get("FILTERSLIST") as FormArray;
        this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));

        this.addFilter.OPERATION =
            event.FILTERTYPE == "DATE"
                ? "is in the last"
                : event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER"
                    ? "is equal to"
                    : "contains";
    }

    /**
     * This function is used to create OP filter data value. 
     */
    createOPFilter(type): FormGroup {
        return this._formBuilder.group({
            OPERATION:
                type == "DATE"
                    ? "is in the last"
                    : type == "CURRENCY" || type == "NUMBER"
                        ? "is equal to"
                        : "contains",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: type,
        });
    }

    /**
     * This functionis used to optional set for the filter
     */
    optionalDateFilter(event, filterIs, val, index) {
        filterIs.selectedFilter = event.value;
        this.selectedFilterIs = event.value;
        if (index != -1) {
            this.documentform.controls.FILTERS["controls"][index].controls[
                "VALUE"
            ].reset();
            this.documentform.controls.FILTERS["controls"][index].controls[
                "VALUE2"
            ].reset();
        }

        this.addFilter["VALUE"] = "";
        this.addFilter["VALUE2"] = "";
    }

    /**
     * This function is used to add the filter data value.
     */
    async addfilter(IsfilterData, oprationalFilter, evt) {
        // alert(" >> value changed >> ")

        if (
            !this.TableColumnsService.checkValidation(
                this.addFilter.VALUE,
                this.addFilter.VALUE2,
                this.addFilter.OPERATION,
                IsfilterData.FILTERTYPE
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }
        await this.addDynamicFilter(IsfilterData);

        let sIndex = this.optionalFilters.length - 1;

        this.documentform.value.FILTERS[sIndex].VALUE = this.addFilter.VALUE;
        this.documentform.value.FILTERS[sIndex].VALUE2 = this.addFilter.VALUE2;
        this.documentform.value.FILTERS[sIndex].OPERATION =
            this.addFilter.OPERATION;
        this.documentform.value.FILTERS[sIndex].FILTERTYPE =
            IsfilterData.FILTERTYPE;
        IsfilterData.AdvancedFilter = this.documentform.value.FILTERS[sIndex];
        let advFilters = JSON.parse(
            localStorage.getItem("Document_Advance_Filter")
        );
        if (advFilters) {
            this.saveAdvanceFilter = advFilters;
        }

        this.saveAdvanceFilter.push(IsfilterData);
        localStorage.setItem(
            "Document_Advance_Filter",
            JSON.stringify(this.saveAdvanceFilter)
        );

        if (this.FILTERS.length == 1) {
            this.documentform.patchValue({
                FILTERS: [
                    {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    },
                ],
            });
        } else {
            this.FILTERS.value.forEach((filterData, index) => {
                if (sIndex == index) {
                    filterData = {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    };
                }
            });

            this.documentform.patchValue({
                FILTERS: this.FILTERS.value,
            });
        }

        //After getting data appy filter.
        this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

        $(".example-containerdata").css(
            "height",
            $(window).height() -
            ($("#filter-form-menu-wrap").height() + 265) +
            "px"
        );
    }

    /**
     * This function is used to add the Dynamic Filter data value
     */
    addDynamicFilter(event, type?) {
        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }



        event.FILTERAPPLY = true; //for filter hide/show from optional filter list.
        if (!type) {
            this.optionalFilters.push(event);
        }
        // OPTIONAL FILTER
        this.FILTERS = this.documentform.get("FILTERS") as FormArray;
        this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));

        setTimeout(() => {
            this.detectedChangeRef = Math.random()
        }, 500)
    }

    /**
     * This function is used to apply the Optional filter
     */
    applayOptionalfilter(
        FilterData,
        isIndex,
        OpretionIs?: any,
        evt?: any,
        isDynamic?
    ) {
        // new code for validation
        if (isDynamic == "isDynamic") {
            this.cdf.detectChanges();
        }
        let saveAdvancefilterData = JSON.parse(
            localStorage.getItem("Document_Advance_Filter")
        );

        const field1Val =
            this.documentform.controls.FILTERS["controls"][isIndex].controls[
                "VALUE"
            ].value;
        const field2Val =
            this.documentform.controls.FILTERS["controls"][isIndex].controls[
                "VALUE2"
            ].value;
        const operationVal =
            this.documentform.controls.FILTERS["controls"][isIndex].controls[
                "OPERATION"
            ].value;
        const filterTypeVal =
            this.documentform.controls.FILTERS["controls"][isIndex].controls[
                "FILTERTYPE"
            ].value;

        if (
            !this.TableColumnsService.checkValidation(
                field1Val,
                field2Val,
                operationVal,
                filterTypeVal
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }

        // ends here ~ new code for validation

        //addData.selectedFilter = OpretionIs.triggerValue;
        let addData = JSON.parse(JSON.stringify(FilterData));
        addData.selectedFilter = OpretionIs.triggerValue;

        const applyFilterData = this.TableColumnsService.Optionalfilter(
            addData,
            isIndex,
            this.optionalDateFilterPayload,
            this.documentform
        );
        this.optionalDateFilterPayload = applyFilterData;
        if (saveAdvancefilterData) {
            if (!saveAdvancefilterData[isIndex]) {
                this.saveAdvanceFilter.push(FilterData);
                saveAdvancefilterData = saveAdvancefilterData.concat(
                    this.saveAdvanceFilter[isIndex]
                );
            }

            saveAdvancefilterData[isIndex].AdvancedFilter.VALUE = field1Val;
            saveAdvancefilterData[isIndex].AdvancedFilter.VALUE2 = field2Val;
            saveAdvancefilterData[isIndex].AdvancedFilter.OPERATION =
                operationVal;
            saveAdvancefilterData[isIndex].AdvancedFilter.FILTERTYPE =
                filterTypeVal;

            saveAdvancefilterData[isIndex].FilterHeadings =
                applyFilterData[isIndex];
            localStorage.setItem(
                "Document_Advance_Filter",
                JSON.stringify(saveAdvancefilterData)
            );
        }
        this.doFilter(this.optionalDateFilterPayload);
    }

    /**
     * This function is used to do the filter date value
     */
    doFilter(mainFilter: any) {
        const payloadData = JSON.parse(JSON.stringify(mainFilter));
        const finalPayload = payloadData
            .filter((d) => d["value"] !== null)
            .map((e) => {
                if (e["value"] && e["value"] !== null) {
                    delete e["value"].COLUMNID;
                    return e["value"];
                }
            });
        const doFilter = this.lastNetDriveFolderFilter;
        //const doFilter = JSON.parse(JSON.stringify(this.filterVals));
        // doFilter.FILTERS = finalPayload;
        doFilter.AdvancedFilters = finalPayload;

        delete doFilter.activeDesc;
        delete doFilter.unbilledWorkDesc;
        delete doFilter.FILTERINDEX;
        delete doFilter.FILTERAPPLAY;

        this.reload(doFilter);
    }

    /**
     * This function is used to reload the data value
     */
    reload(filterVal) {
        delete filterVal.AdvancedFilters
        this.LoadData2(filterVal);
        // this.behaviorService.MatterData(null);
        // this.child.getMatterList(filterVal);
    }

    /**
     * this function is used to reset the optional filter data value. 
     */
    resetOptionalFilter(event, type, isindex) {
        let index = this.optionalFilters.findIndex(
            (e) => e.COLUMNID == event.COLUMNID
        );

        event.FILTERAPPLY = false; //for filter hide/show from optional filter list.
        // original code
        // this.optionalFilterList.map((x)=>{
        //   if(this.optionalFilters.findIndex((e)=> e.COLUMNID == x.COLUMNID) !== -1){
        //     x.FILTERAPPLY = false;
        //   };
        // });
        // ends here ~ original code



        // fixed code to show filter in filter list
        setTimeout(() => {
            this.optionalFilterList.map((x) => {
                if (this.optionalFilters.findIndex((e) => x.COLUMNID == event.COLUMNID) !== -1) {
                    x.FILTERAPPLY = false;
                };
            });

            this.optionalFilterList = this.optionalFilterList
        }, 100);
        // ends here ~ fixed code to show filter in filter list


        this.optionalFilters[isindex]["FILTERAPPLY"] = false;

        setTimeout(() => {
            this.detectedChangeRef = Math.random()
        }, 500)

        this.saveAdvanceFilter.splice(index, 1);
        this.optionalFilters.splice(index, 1);
        this.optionalDateFilterPayload.splice(isindex, 1);
        this.FILTERS.controls.splice(isindex, 1);
        this.documentform.value.FILTERS.splice(isindex, 1);
        //Delete Filters
        const resetFilter = this.lastNetDriveFolderFilter;

        delete resetFilter.activeDesc;
        delete resetFilter.unbilledWorkDesc;
        delete resetFilter.FILTERINDEX;
        delete resetFilter.FILTERAPPLAY;

        const ArrayResetOptional = JSON.parse(
            JSON.stringify(this.documentform.value.FILTERS)
        );

        //Advance Filter.
        this.TableColumnsService.refrshAdvanceFilter(event, "Document");
        const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
            if (this.optionalFilters[index]) {
                data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
            }
            return data;
        });
        const finalPayload = ArrayResetOptionalNew.filter(
            (d) => d["VALUE"] !== null && d["VALUE"] !== ""
        ).map((e) => {
            if (e["VALUE"] && e["VALUE"] !== "") {
                delete e.COLUMNID;
                if (e.FILTERTYPE == "DATE") {
                    let date1 =
                        e && e.VALUE !== ""
                            ? moment(e.VALUE).format("DD/MM/YYYY")
                            : "";
                    let date2 =
                        e && e.VALUE2 !== "" && e.OPERATION == "is between"
                            ? moment(e.VALUE2).format("DD/MM/YYYY")
                            : "";

                    if (e.OPERATION == "is in the last") {
                        e.VALUE = e.VALUE;
                        e.VALUE2 = e.VALUE2;
                    } else {
                        e.VALUE = date1;
                        e.VALUE2 = date2;
                    }
                }

                if (e.VALUE2 == null) {
                    e.VALUE2 = "";
                }
                return e;
            }
        });

        const advanceFilterPayload = this.loadAdvanceFilterPayload();
        resetFilter.AdvancedFilters =
            finalPayload.length !== 0
                ? finalPayload
                : advanceFilterPayload
                    ? advanceFilterPayload
                    : [];

        if (resetFilter.AdvancedFilters.length == 0) {
            delete resetFilter.AdvancedFilters;
            localStorage.removeItem("Document_Advance_Filter");
        }
        // const finalBasicPayload = JSON.parse(JSON.stringify(resetFilter));

        // delete finalBasicPayload.DateRange;
        // delete finalBasicPayload.dayRange;
        // resetFilter.FILTERS = finalPayload;
        resetFilter.AdvancedFilters = finalPayload;
        if (this.FILTERS.controls.length == 0 || finalPayload.length == 0) {
            // delete resetFilter.FILTERS;
            delete resetFilter.AdvancedFilters;
        }

        this.reload(resetFilter);
        // this.reload(finalBasicPayload);
    }

    /**
     * This function is used to load the Advanced Filter payload data value.
     */
    loadAdvanceFilterPayload() {
        let PayloadForAdvFilter = JSON.parse(
            localStorage.getItem("Document_Advance_Filter")
        );
        if (PayloadForAdvFilter) {
            return PayloadForAdvFilter.map((advFilterData) => {
                if (advFilterData && advFilterData.FilterHeadings) {
                    delete advFilterData.FilterHeadings.value.COLUMNID;
                    return advFilterData.FilterHeadings.value;
                }
            });
        } else {
            return null;
        }
    }

    /**
     * This function is used to set the Filter for the UI.
     */
    setFilterUi(filter, index) {
        this.selectedFilterIs =
            this.optionalDateFilterPayload[index]["value"]["OPERATION"];
    }

    /**
     * This fuinction is used to set the Default Advanced filter.
     */
    async setDefaultAdvanceFilter() {
        // Advance Filter If Already have.
        let advanceFiltes = JSON.parse(
            localStorage.getItem("Document_Advance_Filter")
        );


        if (advanceFiltes) {
            this.optionalDateFilterPayload = [];
            this.optionalFilters = advanceFiltes;
            await advanceFiltes.forEach(async (filterData, index) => {
                await this.addDynamicFilter(
                    filterData.AdvancedFilter,
                    "default"
                );

                this.FILTERS.value[index].OPERATION = await filterData
                    .AdvancedFilter.OPERATION;
                this.FILTERS.value[index].FILTERTYPE = await filterData
                    .AdvancedFilter.FILTERTYPE;
                this.FILTERS.value[index].VALUE = await filterData
                    .AdvancedFilter.VALUE;
                this.FILTERS.value[index].VALUE2 = await filterData
                    .AdvancedFilter.VALUE2;
                await this.optionalDateFilterPayload.push(
                    filterData.FilterHeadings
                );

                await this.documentform.patchValue({
                    FILTERS: this.FILTERS.value,
                });
            });
        }
    }

    /**
     * This function is used to nfAfterContent checked
     */
    ngAfterContentChecked() {
        $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + 200)) + 'px');
        $(window).resize(function () {
            $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + 210)) + 'px');
        });
    }

    /**
     * This function is used to change the SubfolderInclude.
     */
    changeSubFolderInclude() {
        this.LoadData2({ DONTRECURSESUBFOLDERS: this.includeSubFolder, DONTADDROOTENTRY: true, FOLDERNAME: '' })
        this.LoadData()
    }

    //   onResizeColumnSetting(event , index){
    //     //event : selected column.
    //     //index : selected column index.
    //     //renderer : dont make this comman it's give issue import in your component.
    //     //displayedColumns : displayedColumns name's (Table Header names).
    //     //matTableRef : most importent please import in component wise don't make it comman.
    //     // last field is localstorage name which use to store columnObj
    //     this.TableColumnsService.onResizeColumn(event , index , this.renderer , this.displayedColumns , this.matTableRef , this.ColumnsObj , 'matterdoc_list_columns');
    //   };

    //For resize this fuction is most impotent don't make it comman it's give issue in future.
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
    }

    // Before Delete Event for Manual API Call
//   onBeforeDelete(args: any): void {
//     console.log('.................................>>>',args);
//      // Prevent the default deletion process
//      //args.cancel = true;
//     this.DocRegData=args.itemData[0];
//     // Make an API call to your server-side deletion endpoint
//     this.confirmDialogRef2 = this.dialog.open(ConfirmPopupComponent, {
//         disableClose: true,
//         width: "100%",
//         data: "",
//     });
//     this.confirmDialogRef2.componentInstance.confirmMessage =
//         "Do you want to delete this file from your " +
//         this.currentUser.DOCUMENTSYSTEM +
//         " also?";
//     this.confirmDialogRef2.componentInstance.confirmTitle = "Confirm";
//     this.confirmDialogRef2.componentInstance.confirm1 = "Yes";
//     this.confirmDialogRef2.componentInstance.confirm2 = "No";
//     this.confirmDialogRef2.componentInstance.confirm3 = "Cancel";
//     this.confirmDialogRef2.afterClosed().subscribe((result) => {
//         if (result && result !== 2) {
//             let postData = {
//                 FormAction: "delete",
//                 DELETENETDRIVEFILE: true,
//                 data: { DOCUMENTGUID: this.DocRegData.DOCUMENTGUID },
//             };
//             let RefreshIdArray: any = ["#refreshDOCREGTab"];
//             this.toolbarServiceService.SetVariableForRetry(
//                 postData,
//                 "SetDocument",
//                 RefreshIdArray
//             );
//             this._mainAPiServiceService
//                 .getSetData(postData, "SetDocument")
//                 .subscribe((res) => {
//                     if (res.STATUS == "success" && res.CODE == 200) {
//                         args.cancel = false;
//                         const fileManager = (document.querySelector('ejs-filemanager') as any).ej2_instances[0];
//                         console.log(fileManager);
//                         fileManager.deleteFiles(args.files);
//                         this.toastr.success("Deleted successfully");
//                     }
//                 });
//         } else if (result == false) {
//             let postData = {
//                 FormAction: "delete",
//                 data: { DOCUMENTGUID: this.DocRegData.DOCUMENTGUID },
//             };
//             let RefreshIdArray: any = ["#refreshDOCREGTab"];
//             this.toolbarServiceService.SetVariableForRetry(
//                 postData,
//                 "SetDocument",
//                 RefreshIdArray
//             );
//             this._mainAPiServiceService
//                 .getSetData(postData, "SetDocument")
//                 .subscribe((res) => {
//                     if (res.STATUS == "success" && res.CODE == 200) {
//                         localStorage.setItem(
//                             "istrackid",
//                             this.oldIsTrackId
//                         );
//                         $("#refreshDOCREGTab").click();
//                         this.toastr.success("Deleted successfully");
//                     }
//                 });
//         }
//     });

//   }

//   // Success Event to track other actions
//   onSuccess(args: any): void {
//     console.log('Success action:', args);
//   }


//    // Event for File Selection
//    onFileSelected(args: any): void {
//     console.log('File selected:', args.fileDetails);
//   }

/**
 * This function is used to delete the file using the toolbar.
 * @param data -file data
 */
  onFileSelect(data){
    this.TypeNameData=data.fileDetails.type; 
    if(data.action == 'select'){
        this.behaviorService.DocumentRegisterData(data.fileDetails);
    }else{
        this.behaviorService.DocumentRegisterData(null);
    }
    
  }

  created(data) {
    this.fileManagerInstance.uploadObj.selected = function (args) {
      console.log('args:...............', args.filesData);
    };
  }

  async uploadListCreate(args: any) {
    const file = args.fileInfo.rawFile; // Assuming args.fileInfo.rawFile is the file object
    let date = new Date(file.lastModified);
    try {
        showSpinner(document.getElementById('containerdata'));
      const base64 = await this.convertFileToBase64(file);
      let details: any = {
        GENERATEDATE: date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear(),
        GENERATETIME: this.formatAMPM(date),
        CONTEXT: "Matter",
        DOCUMENTCLASS: "External Document",
        DESCRIPTION: file.name,
        DRAFTSTATUS: 'Complete',
        DOCUMENTTYPE: 'Electronic Document',
        DOCUMENTAUTHOR: this.logedInUser.UserName,
        DOCUMENTNAME: file.name,
        MATTERGUID: this.activeMATTERGUID,
        FILEDATA: base64,
        FOLDERNAME: this.FULLPATH,
       // FOLDERNAME: this.lastNetDriveFolderFilter.FOLDERNAME

    };
    let postData: any = { FORMACTION: 'insert', VALIDATEONLY: true, Data: details };
    this._mainAPiServiceService.getSetData(postData, 'SetDocument').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        postData.VALIDATEONLY = true;
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
            this.checkValidation(response.DATA.VALIDATIONS, postData);
        } else if (response.CODE == 451 && response.STATUS == 'warning') {
            this.checkValidation(response.DATA.VALIDATIONS, postData);
        } else if (response.CODE == 450 && response.STATUS == 'error') {
            this.checkValidation(response.DATA.VALIDATIONS, postData);
        }
    }, error => {
        hideSpinner(document.getElementById('containerdata'));
        this.toastr.error(error);
    });
    } catch (error) {
      hideSpinner(document.getElementById('containerdata'));
      console.error('Error while converting file to Base64:', error);
    }
  }
  
  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        resolve(reader.result as string);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  beforePopupOpen(args){
    if(args.popupName == "Upload"){
      args.cancel = true;
    }

   if(this.TypeNameData == 'folder'){

    // Get the dialog content by ID
const dialogContent = document.getElementById('file-manager_dialog_dialog-content');

// Check if the element exists
if (dialogContent) {
  // Find the 'Size' row
  const sizeRow = Array.from(dialogContent.querySelectorAll('tr')).find((row) => {
    return row.querySelector('td')?.innerText === 'Modified';
  });

  const sizeRoww = Array.from(dialogContent.querySelectorAll('tr')).find((row) => {
    return row.querySelector('td')?.innerText === 'Size';
  });
  // Remove the row if it exists
  if (sizeRow) {
    sizeRow.remove();
  }

  if (sizeRoww) {
    sizeRoww.remove();
  }
}
     return
      }

    if (args.popupName === 'File Details') {


// Get the dialog content by ID
const dialogContent = document.getElementById('file-manager_dialog_dialog-content');

// Check if the element exists
if (dialogContent) {
  // Find the 'Size' row
  const sizeRow = Array.from(dialogContent.querySelectorAll('tr')).find((row) => {
    return row.querySelector('td')?.innerText === 'Size';
  });

  // Remove the row if it exists
  if (sizeRow) {
    sizeRow.remove();
  }
}

        const dialogElementt = document.getElementById('file-manager_dialog');
        if (dialogElementt) {
            dialogElementt.style.width = '500px'; // Set the width to 500px
          }
        const table = dialogContent?.getElementsByTagName('table')[0];
        if (table) {

            const rowCount = table.rows.length;

            if ((this.fileManagerInstance as any).selectedItems?.length === 1) {
                //1
                const roww = table.insertRow(rowCount);
                const cellKeyy = roww.insertCell(0);
                const cellValuey = roww.insertCell(1);
                cellKeyy.innerText = 'Author';
                cellValuey.innerText = (this.fileManagerInstance as any).itemData[0].DOCUMENTAUTHOR;
                 //2
                 const rowww = table.insertRow(rowCount);
                 const cellKeyyy = rowww.insertCell(0);
                 const cellValueyy = rowww.insertCell(1);
                 cellKeyyy.innerText = 'User Id';
                 cellValueyy.innerText = (this.fileManagerInstance as any).itemData[0].USERID;
                 //3
                const row = table.insertRow(rowCount);
                const cellKey = row.insertCell(0);
                const cellValue = row.insertCell(1);
                cellKey.innerText = 'FileName';
                cellValue.innerText = (this.fileManagerInstance as any).itemData[0].name;

                //4

                const row4 = table.insertRow(rowCount);
                const cellKey4 = row4.insertCell(0);
                const cellValue4 = row4.insertCell(1);
                cellKey4.innerText = 'Template Used';
                cellValue4.innerText = (this.fileManagerInstance as any).itemData[0].TEMPLATENAME;
            }

        }

      }

  }

   // Triggered when an item is double-clicked in the File Manager
   onFileOpen(data: any,Alldata:any): void {
    if(data.fileDetails.type == 'folder'){
        localStorage.setItem('sendSaveFolder', data.fileDetails.FULLPATH);
        this.lastNetDriveFolderFilter.FOLDERNAME=data.fileDetails.FULLPATH;
        this.FULLPATH=data.fileDetails.FULLPATH;
        this.Foldername=data.fileDetails.name;
        // As per the new Development When user selects the folder then we call the double click function.
        this.doubleClick(data.fileDetails, Alldata);
    }
    const currentPath = this.fileManagerInstance.path;
   this.behaviorService.DocumentRegisterData(data.fileDetails,this.fileManagerInstance);


   if (data.target && data.target.classList.contains('e-treeview')) {
    //console.log('Folder selected from the left navigation pane:', data.fileDetails.name);
  }
  // Check if selection came from the right details pane
  else if (data.target && data.target.classList.contains('e-gridcontent')) {    
   // console.log('Folder selected from the right details pane:', data.fileDetails.name);
  }
  }

 onFileDragStart(args: any) {
    console.log('File drag start',args);
 }

onFileDragStop(event: any) {
    if (event.fileDetails[0] && event.fileDetails[0].isFile === false) {
        event.cancel = true; // Prevent dragging folders
      }
 }

  onFileDragging(args: any) {
    //console.log('File dragging',args);
  }


  onFileDropped(args: any) {
    showSpinner(document.getElementById('containerdata'));
    let  filterPath;
    if (args.fileDetails[0].filterPath.endsWith("\\")) {
        filterPath = args.fileDetails[0].filterPath.slice(0, -1); // Remove the last character
      }else{
        filterPath = args.fileDetails[0].filterPath
      }
   // let  filterPath = args.fileDetails[0].filterPath.replace(/\\$/, '');
    this.isLoadingResults = true;  
    let postData: any = { DOCUMENTGUID: args.fileDetails[0].DOCUMENTGUID, NEWDOCUMENTNAME: args.fileDetails[0].documentName ? args.fileDetails[0].documentName : '' };
    postData.NEWFOLDERNAME = filterPath;
    this._mainAPiServiceService.getSetData(postData, 'NetDriveRenameDocument').subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
            this.behaviorService.DocumentRegisterNewData$.next(args.fileDetails[0]);
            setTimeout(() => {
                this.lastNetDriveFolderFilter.FOLDERNAME=`${filterPath}`
                this.lastNetDriveFolderFilter.DONTRECURSESUBFOLDERS=false
                this.FULLPATH = `${filterPath}`, 
                this.Foldername = args.fileDetails[0].name
                this.DocRegData.DOCUMENTGUID=args.fileDetails[0].DOCUMENTGUID
                // this.doubleClick(args.fileDetails[0]);
                $('#refreshDOCREGTab').click();
            },200);
            this.toastr.success("Moved successfully");
            hideSpinner(document.getElementById('containerdata'));
        } else if (response.CODE == 432 || response.CODE == '432') {    
            let payload = {
                "Action": "OAuth2Start",
                "VALIDATEONLY": false,
                "Data": {
                    "USERGUID": this.currentUser.UserGuid,
                    "AuthType": "NetDrive"
                }
            }
            this._mainAPiServiceService
            .getSetData(payload, 'oauth-connections')
            .subscribe((response) => {
                if(response.CODE == 200 && response.STATUS == "success") {
                    window.open(
                        response.DATA[0].AuthorizationUrl,
                        "targetWindow",
                        `toolbar=no,
                        location=no,
                        status=no,
                        menubar=no,
                        scrollbars=yes,
                        resizable=yes,
                        width=800,s
                        height=600`
                    );
                }
            });
            hideSpinner(document.getElementById('containerdata'));
        } else if (response.CODE == 433 || response.CODE == '433') {
            this.isLoadingResults = false
           // this.dialogRef.close();
           hideSpinner(document.getElementById('containerdata'));
        }
        this.isLoadingResults = false;
    }, error => {
        hideSpinner(document.getElementById('containerdata'));
        this.isLoadingResults = false;
        this.toastr.error(error);
    });
  }

  public popupOpen(args) {
    if (
      args.popupName == 'Error' &&
      args.element.querySelector('.e-dlg-content').innerText ==
        'Cannot load empty data within the File Manager.'
    ) {
      args.element.remove();
      createSpinner({
        // Specify the target for the spinner to show
        target: document.getElementById('containerdata'),
      });
      // showSpinner() will make the spinner visible
      showSpinner(document.getElementById('containerdata'));
    }
  }

}
