import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TreeGridComponent } from '@syncfusion/ej2-angular-treegrid';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from "@angular/material/dialog";
import { BankingDialogComponent } from '@pages/banking/banking-dialog.component';
import { Subject, takeUntil } from 'rxjs';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { HttpClient } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-unpaid-account',
  templateUrl: './unpaid-account.component.html',
  styleUrls: ['./unpaid-account.component.scss']
})
export class UnpaidAccountComponent implements OnInit {
  isLoadingResults: boolean;
  TreegridData: any;
  isShownTreegrid:boolean=true;
  dialogheader='Unpaid Accounts'
  @ViewChild('treegrid') treegrid!: TreeGridComponent;
  displayedColumns: string[] = ['select', 'RECEIVEDDATE', 'PAYEE', 'AMOUNTINCGST', 'NOTE'];
  displayedSecondColumns: string[] = ['AMOUNTINCGST','RECEIVEDDATE', 'PAYEE', 'NOTE','PAYEECONTACTBANKBSB','PAYEECONTACTBANKACCOUNTNUMBER','PAYEECONTACTBANKACCOUNTNAME'];
  selection = new SelectionModel<any>(true, []);
  SHOWSECONDTABLE;
  totalAmountIncGST: any;
  unpaideForm:FormGroup;
  ChartHandlingData: { ClickType: string; UseTrust: string; PopUp: string; Lable: string; };
  SubData: any[];
  private _unsubscribeAll$: Subject<void> = new Subject();
  errorWarningData: any = {};
  isShownDownload: boolean=false;
  ABAFILENAME: any;
  searchDataValue='';
  constructor(private fileSaverService: FileSaverService,public http:HttpClient,public globalFunService:GlobalFunctionsService ,public MatDialog: MatDialog,private datepipe:DatePipe ,private fb:FormBuilder,private toastr: ToastrService,public dialogRef: MatDialogRef<UnpaidAccountComponent>,public _mainAPiServiceService: MainAPiServiceService,private behaviorService:BehaviorService) { }

   treeGridData = [
    {
      taskID: 1,
      taskName: 'Planning',
      startDate: new Date('2023-01-01'),
      endDate: new Date('2023-01-10'),
      progress: '50%',
      subtasks: [
        {
          taskID: 2,
          
          startDate: new Date('2023-01-01'),
          endDate: new Date('2023-01-03'),
          progress: '100%',
        },
        {
          taskID: 3,
          taskName: 'Plan budget',
          startDate: new Date('2023-01-04'),
          endDate: new Date('2023-01-06'),
         
        },
      ],
    },
    {
      taskID: 4,
      taskName: 'Development',
      startDate: new Date('2023-01-11'),
      endDate: new Date('2023-01-20'),
      progress: '20%',
      subtasks: [
        {
          taskID: 5,
          taskName: 'Develop feature A',
          startDate: new Date('2023-01-11'),
          endDate: new Date('2023-01-15'),
          progress: '40%',
        },
        {
          taskID: 6,
          taskName: 'Develop feature B',
          startDate: new Date('2023-01-16'),
          endDate: new Date('2023-01-20'),
          progress: '10%',
        },
      ],
    }, {
      taskID: 7,
      taskName: 'Development',
      startDate: new Date('2023-01-11'),
      endDate: new Date('2023-01-20'),
      progress: '20%',
      subtasks: [
        {
          taskID: 8,
          taskName: 'Develop feature A',
          startDate: new Date('2023-01-11'),
          endDate: new Date('2023-01-15'),
          progress: '40%',
        },
        {
          taskID: 9,
          taskName: 'Develop feature B',
          startDate: new Date('2023-01-16'),
          endDate: new Date('2023-01-20'),
          progress: '10%',
        },
      ],
    },
  ];
  ngOnInit(): void {
    this.unPaidFormData()
    this.getDefaultData();
    setTimeout(() => {
      this.getAllUnpaidData();
    },500);
  }

getAllUnpaidData(){
  this.isLoadingResults = true;
  this._mainAPiServiceService.getSetData({ Action: 'Getdata', Filters: { 
        "EXPENDITURECLASS": " ",
        "INCURREDSTARTDATE": "",
        "INCURREDENDDATE": "",
        "PAIDSTARTDATE": "",
        "PAIDENDDATE": "",
        "Search": this.searchDataValue,
        "Status": "Unpaid"
      } }, 'expenditure').subscribe((response) => {
    if (response.CODE == 200 && response.STATUS == "success") {
      if(response.DATA.RECORDS){
        this.TreegridData =new MatTableDataSource<any>(response.DATA.RECORDS.filter(data=> delete data.EXPENDITUREITEMS)) ;
      }
      this.isLoadingResults = false;
    } else if (response.MESSAGE == "Not logged in") {
        this.dialogRef.close(false);
        this.isLoadingResults = false;
    }
    this.isLoadingResults = false;
}, (error) => {
    this.toastr.error(error);
});
}

/** Whether the number of selected elements matches the total number of rows. */
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.TreegridData?.data?.length;
  return numSelected === numRows;
  
}

/** Selects all rows if they are not all selected; otherwise clear selection. */
toggleAllRows() {
  console.log(this.selection.selected)
  if (this.isAllSelected()) {
    this.selection.clear();
    return;
  }

  this.selection.select(...this.TreegridData.data);

  console.log('SELECTED DATA',this.selection.selected);
}

/** The label for the checkbox on the passed row */
checkboxLabel(row?: any): string {
  if (!row) {
    return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
  }
  return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
}

goToNext(){
  this.isShownTreegrid = false;
  // this.selection.selected.forEach(element => {
  //   element.PAYEECONTACTBANKBSB = '';
  //   element.PAYEECONTACTBANKACCOUNTNUMBER = '';
  //   element.PAYEECONTACTBANKACCOUNTNAME = ''
  // });
  this.totalAmountIncGST = this.selection.selected.reduce((total, item) => {
    return total + (item.AMOUNTINCGST || 0); // Add the value if it exists, otherwise add 0
  }, 0);
  
  //console.log("Total AMOUNTINCGST:", totalAmountIncGST);

  this.SHOWSECONDTABLE=new MatTableDataSource<any>(this.selection.selected);

}



getDefaultData(){
  this._mainAPiServiceService.getSetData({ FormAction: "default", Data: { } }, 'expenditure-batch').subscribe(data=>{
    if(data.CODE== 200 && data.STATUS == 'success'){
let DefaultData=data.DATA.DEFAULTVALUES;
      this.unpaideForm.patchValue({
        EXPENDITUREBATCHGUID: DefaultData.EXPENDITUREBATCHGUID || '',
        BATCHDATE: DefaultData.BATCHDATE || '',
        TOTALAMOUNT: DefaultData.TOTALAMOUNT || '',
        BATCHSTATUS: DefaultData.BATCHSTATUS || '',
        EFTREFERENCE: DefaultData.EFTREFERENCE || '',
        BANKACCOUNTGUID: DefaultData.BANKACCOUNTGUID || '',
        BANKACCOUNTDATA: DefaultData.BANKACCOUNTDATA || '',
        BATCHDATETEXT: DefaultData.BATCHDATE || ''

      });

      let ReceiveDATE;
      if (DefaultData && DefaultData.BATCHDATE) {
        let DateIncurred = DefaultData.BATCHDATE.split("/");
        ReceiveDATE = new Date(DateIncurred[1] + "/" + DateIncurred[0] + "/" + DateIncurred[2]);
        console.log(ReceiveDATE);
        this.unpaideForm.controls["BATCHDATETEXT"].setValue(ReceiveDATE);
    }

if(DefaultData && DefaultData.BANKACCOUNTNAME && DefaultData.BANKACCOUNTNUMBER){
  this.unpaideForm.controls["BANKACCOUNTDATA"].setValue(DefaultData.BANKACCOUNTNUMBER + " " + DefaultData.BANKACCOUNTNAME);
  this.unpaideForm.controls["BANKACCOUNTGUID"].setValue(DefaultData.BANKACCOUNTGUID);
}

    }
  })
}

get f() {
  return this.unpaideForm.controls;
}

unPaidFormData(){
  this.unpaideForm=this.fb.group({
        "EXPENDITUREBATCHGUID": [''],
        "BATCHDATE":[''],
        "TOTALAMOUNT":[''],
        "BATCHSTATUS":[''],
        "EFTREFERENCE": [''],
        "BANKACCOUNTGUID":[''],
        "BANKACCOUNTDATA":[''],
        'BATCHDATETEXT':['']
  })
}

 /**
     * Used to get the updated data.
     * @param type -
     * @param event -get the updated date
     */
 CommencementDate(event: MatDatepickerInputEvent<Date>):void {
  this.unpaideForm.controls["BATCHDATE"].setValue(
      this.datepipe.transform(event.value, "dd/MM/yyyy")
  );
}

/**
     * This function is used to open the banking dialog data.
     * @param type -name of the type
     * @param clickType -type of the click
     * @param evt -value of the event
     * @returns 
     */
BankingDialogOpen(type: any, clickType, evt?: any) {
  if (evt && evt.pointerType == "") {
      return;
  }
  // for by default bank account select not trust account
  this.ChartHandlingData = { ClickType: "WithoutTrust", UseTrust: "No", PopUp: "", Lable: "Select Account", };
  localStorage.setItem("ChartURL", JSON.stringify(this.ChartHandlingData));
  this.behaviorService.TrustDuplicateModuleHandling(this.ChartHandlingData);
  const dialogRef = this.MatDialog.open(BankingDialogComponent, { disableClose: true, width: "100%", data: { AccountType: type, FromWhere: "spendMonyExpense" }, });
  dialogRef.afterClosed().subscribe((result) => {

      localStorage.setItem('istrackid', 'SpandMoneyDialog');

      if (result) {
         
              this.unpaideForm.controls["BANKACCOUNTDATA"].setValue(result.ACCOUNTCLASS + " - " + result.ACCOUNTNUMBER + " " + result.ACCOUNTNAME);
              this.unpaideForm.controls["BANKACCOUNTGUID"].setValue(result.ACCOUNTGUID);
          
      }
  });
}

ngOnDestroy(): void {
  this._unsubscribeAll$.next();
  this._unsubscribeAll$.complete();
}

/**
     * Used to get the default protected data.
     */
SaveUnpaidAccounts():void {
  this.isLoadingResults = true;
  this.SubData=[];
  this.SHOWSECONDTABLE.data.forEach(element => {
    this.SubData.push({ BSB:element.PAYEECONTACTBANKBSB,EXPENDITUREGUID:element.EXPENDITUREGUID,ACCOUNTNAME:element.PAYEECONTACTBANKACCOUNTNAME,ACCOUNTNUMBER:element.PAYEECONTACTBANKACCOUNTNUMBER})
  });
  const commonPayload = {
      "EXPENDITUREBATCHGUID": this.f.EXPENDITUREBATCHGUID.value,
    "BATCHDATE": this.f.BATCHDATE.value,
    "TOTALAMOUNT": this.totalAmountIncGST,
    "BATCHSTATUS": this.f.BATCHSTATUS.value,
    "EFTREFERENCE": this.f.EFTREFERENCE.value,
    "BANKACCOUNTGUID": this.f.BANKACCOUNTGUID.value,
    "EXPENDITUREBATCHITEMS":this.SubData
  };
  let finalpayload = {
      Action: "insert",
      VALIDATEONLY: true,
      UseTrust: false,
      DATA: {...commonPayload},
  };
  this._mainAPiServiceService
      .getSetData(finalpayload, "expenditure-batch")
      .pipe(takeUntil(this._unsubscribeAll$))
      .subscribe(
          (res) => {
              if (res.CODE == 200 && res.STATUS == "success") {
                  this.checkValidation(
                      res.DATA.VALIDATIONS,
                      finalpayload,
                      "expenditure-batch"
                  );
              } else if (res.CODE == 451 && res.STATUS == "warning") {
                  this.checkValidation(
                      res.DATA.VALIDATIONS,
                      finalpayload,
                      "expenditure-batch"
                  );
              } else if (res.CODE == 450 && res.STATUS == "error") {
                  this.checkValidation(
                      res.DATA.VALIDATIONS,
                      finalpayload,
                      "expenditure-batch"
                  );
              } else if (res.MESSAGE == "Not logged in") {
                  this.dialogRef.close(false);
              }
          },
          (err) => {
              this.isLoadingResults = false;
              this.toastr.error(err);
          }
      );
}

/**
     * Used to check the validation status
     * @param bodyData -Data whitch is come to the API.
     * @param details -Updated data
     * @param APIURL -API end point
     */
async checkValidation(bodyData: any, details: any, APIURL: any):Promise<void> {
  this.isLoadingResults = false;
  await this.globalFunService
      .checkValidation(bodyData, details)
      .pipe(takeUntil(this._unsubscribeAll$))
      .subscribe((data) => {
          if (data) {
              this.errorWarningData = data.errorWarningData;
              // this.errorWarningDataArray = data.errorWarningData;
              if (data.callback) {
                  //this.SafeCusodySave(details, APIURL)
                  this.saveUnpaidSpendData(details, APIURL);
              }
          }
      });
}

/**
     * Used to save the protected trust data
     * @param data -Data which we need to pass to request body.
     * @param APIURL -API end point.
     */
saveUnpaidSpendData(data: any, APIURL:string):void {
  data.VALIDATEONLY = false;
  this._mainAPiServiceService
      .getSetData(data, APIURL)
      .pipe(takeUntil(this._unsubscribeAll$))
      .subscribe(
          (response) => {
              if (
                  response.CODE == 200 &&
                  (response.STATUS == "OK" ||
                      response.STATUS == "success")
              ) {
                  this.toastr.success("save successfully");
                  this.isLoadingResults = false;
                  this.isShownDownload=true;
                  this.ABAFILENAME=response.DATA.ABAFILENAME;
                 // this.dialogRef.close(true);
              } else if (
                  response.CODE == 451 &&
                  response.STATUS == "warning"
              ) {
                  this.toastr.warning(response.MESSAGE);
              } else if (
                  response.CODE == 450 &&
                  response.STATUS == "error"
              ) {
                  this.toastr.error(response.MESSAGE);
              } else if (response.MESSAGE == "Not logged in") {
                  this.dialogRef.close(false);
              }
              this.isLoadingResults = false;
          },
          (error) => {
              this.toastr.error(error);
          }
      );
}

DownloadUnpaidAccounts(){
  this.isLoadingResults=true
  this.http.get(this.ABAFILENAME, { responseType: 'blob' }).subscribe(
    (response: Blob) => {
      this.fileSaverService.save(response, this.extractFileName(this.ABAFILENAME));
      this.isShownDownload=false;
      setTimeout(() => {
        this.dialogRef.close(true);
      },500);
      this.isLoadingResults=false
    },
    error => {
      this.isShownDownload=false;
      this.isLoadingResults=false;
      console.error('Download error:', error);
      this.dialogRef.close(false);
    }
  );
}


extractFileName(url: string): string {
  try {
    // Parse the URL to ensure it's valid
    const parsedUrl = new URL(url);

    // Get the pathname and extract the file name dynamically
    const pathname = parsedUrl.pathname;
    return pathname.substring(pathname.lastIndexOf("/") + 1, pathname.indexOf("?") > -1 ? pathname.indexOf("?") : undefined);
  } catch (error) {
    console.error("Invalid URL:", error);
    return ""; // Return an empty string if URL is invalid
  }
}

onSearch(){
  this.getAllUnpaidData();
}

}
