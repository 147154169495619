<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>

<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title> Add Tasks to Matter
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>

    <div mat-dialog-content class="mat-typography main generate-docwrap" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start">
                <form name="matterdetailForm" id="matterdetailForm" [formGroup]="matterdetailForm">
                    <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline">
                        <mat-label>Class</mat-label>
                        <mat-select  formControlName="MATTERCLASS" (ngModelChange)="Classtype($event)">
                            <mat-option *ngFor="let Classoption of Classdata" value="{{Classoption.LOOKUPFULLVALUE}}">
                                {{Classoption.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
            <div fxLayout="row" fxLayoutAlign="start" class="letterhead-container">
                <div fxFlex="35" class="left-section">
                    <h3>All Packs for Leasing</h3>
                    <div class="example-containerdata resizable-table">
                        <mat-table [dataSource]="TaskdataList" [@animateStagger]="{value:'50'}" class="mattempletelist">
                            <ng-container matColumnDef="PACKDESCRIPTION">
                                <mat-header-cell *matHeaderCellDef>
                                    Description
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" style="width: 100px !important; margin-right: 0 !important;"> {{row.PACKDESCRIPTION }} </mat-cell>
                            </ng-container>
                            <mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true">
                            </mat-header-row>
                            <mat-row [style.background]="highlightedRowsList == row.TASKPACKGUID ? selectedColore : ''" [ngClass]="highlightedRowsList == row.TASKPACKGUID ? 'row-text-colore' : ''" highlightedRowsList=row.TASKPACKGUID *matRowDef="let row; columns: displayedColumns;" (click)="highlightedRowsList = row.TASKPACKGUID; clickRow(row);" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                            </mat-row>
                        </mat-table>
                    </div>
                </div>

                <div fxFlex="65" class="right-section">
                    <h3>Tasks that will be created</h3>
                    <div class="example-containerdata resizable-table">
                        <mat-table [dataSource]="Task_Pack_Item_dataSource" [@animateStagger]="{value:'50'}" class="mattempletelist">
                            <ng-container matColumnDef="ROLEASSIGNEDTO">
                                <mat-header-cell *matHeaderCellDef>
                                    Assigned To
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" style="margin-right: 0 !important;"> {{row.ROLEASSIGNEDTO }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="DUEDATEDESCRIPTION">
                                <mat-header-cell *matHeaderCellDef>
                                    Due Date
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" style="margin-right: 0 !important;"> {{row.DUEDATEDESCRIPTION }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="TASKDESCRIPTION">
                                <mat-header-cell *matHeaderCellDef>
                                    Description
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" style="margin-right: 0 !important;"> {{row.TASKDESCRIPTION }} </mat-cell>
                            </ng-container>
                            <mat-header-row class="tbl_fix_header" *matHeaderRowDef="columnsToDisplay; sticky: true">
                            </mat-header-row>
                            <mat-row [style.background]="highlightedRows == row.TASKPACKITEMGUID ? selectedColore : ''" [ngClass]="highlightedRows == row.TASKPACKITEMGUID ? 'row-text-colore' : ''" highlightedRows=row.TASKPACKITEMGUID *matRowDef="let row; columns: columnsToDisplay;" (click)="highlightedRows = row.TASKPACKITEMGUID; clickRowTP(row);" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                            </mat-row>
                        </mat-table>
                    </div>
                </div>
        </div>
    </div>

    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="taskToMatter()">
            Add Tasks to Matter
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>
            Close
        </button>
    </mat-dialog-actions>
</div>